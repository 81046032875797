
.content {
    margin-top: 1em;
    padding: 0em;
    grid-column: 2 / 3;
    h1 {
        margin: 0;
    }
  }

.container {
    &::before {
        content: "";
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, hsl(0deg, 0, 50%) 50%, rgba(255,255,255,0) 100%);
        height: 2px;
        display: block;
        position: relative;

        $widths: 90%;
        width: $widths;
        margin-left: calc(( 100% - #{$widths} ) / 2 );

        margin-top: 3.5em;
        margin-bottom: 3em;
    }
    &__infodiv {
        padding-left: 1em;
        padding-right: 1em;
        & > h2 {
            margin-top: 0;
            margin-bottom: 0.5em;
        }
        &__time {
            text-align: end;
            margin: 0.2em;
        }
    }
    &__imgdiv {
        height: min-content;
        &__img {
            width: 100%;
        }
    }
}

.mobilepadding {
    padding-left: 1em;
    padding-right: 1em;
}

.clicktags {
    padding-bottom: 1em;
}

.tag {
    display: inline-block;
    margin: 0.2em 1em 0em 0em;
    padding: 0.1em 0.5em;
    border-radius: 0.2em;
    user-select: none;
    cursor: pointer;
    color: var(--clr-text-hard);
    background-color: var(--clr-bg-soft);
    &.active {
        color: var(--clr-bg-hard);
        background-color: var(--clr-text-hard);
    }
}

.project-link-div {
    margin: 0.5em 1em;

    & > a {
        text-decoration: none;
        color: var(--clr-text-hard);

        &:hover {
            text-decoration: underline;
        }
    }
}


/* for desktop */
@media only screen and (min-width: 40em) {
    .container {
        display: flex; 
        flex-direction: row-reverse;
        margin: 5em 0em;
        border: none;
        &::before {
            content: none;
        }
        &__infodiv {
            width: 37%;
            box-sizing: border-box;
            padding: 0em 1em;
        }
        &__imgdiv {
            width: 66%;
            box-sizing: border-box;
        }
    }
}