.darkTheme {
  --clr-nav: #2f3036;
  --clr-bg-hard: #2f3036;
  --clr-bg-soft: #494b53;
  --clr-text-soft: #ddd;
  --clr-text-hard: white;
  --clr-shadow: rgba(0, 0, 0, 0.3);
  --bx-shadow: 0px 3px 8px var(--clr-shadow);
  --border: 1px solid var(--clr-text-soft);
  --invert-svg: 1;
}

.lightTheme {
  --clr-nav: rgba(255, 255, 255, 1);
  --clr-bg-hard: white;
  --clr-bg-soft: #ddd;
  --clr-text-soft: #222;
  --clr-text-hard: black;
  --clr-shadow: rgba(0, 0, 0, 0.15);
  --bx-shadow: 0px 3px 8px var(--clr-shadow);
  --invert-svg: 0;
}
html {
  overflow: auto;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
}
.App {
  background: var(--clr-bg-hard);
  color: var(--clr-text-soft);
  min-height: 100vh;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
}

@function inverts($value) {
  @return #{ "invert(" + $value + ")" };
}

.theme-toogle {
  position: absolute;
  right: 0;
  height: 100%;
  width: 6em;
  border: none;
  background-color: transparent;
  outline: none;
  img {
    height: 2em;
    filter: inverts(var(--invert-svg));
  }
}

p {
  color: var(--clr-text-soft);
}

.header {
  background: var(--clr-nav);
  height: 4em;
  text-align: center;
  position: fixed;
  z-index: 30;
  width: 100%;
  border-bottom: var(--border);
}

.nav-toggle {
  display: none;
}

.nav-toggle-label {
  user-select: none;
  position: absolute;
  align-items: center;
  display: flex;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: 1em;
  span,
  span::before,
  span::after {
    display: block;
    width: 2em;
    height: 3px;
    position: relative;
    background: var(--clr-text-hard);
    transition: transform 350ms ease-in-out;
  }
  span::after,
  span::before {
    content: "";
    position: absolute;
  }
  span::before {
    transform: translateY(-8px);
  }
  span::after {
    transform: translateY(8px);
  }
}

input.nav-toggle:checked ~ nav {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 350ms ease-in-out;
}

input.nav-toggle:checked ~ label {
  span {
    transform: rotate(135deg);
    transition: transform 350ms ease-in-out;
  }
  span::before {
    transform: translateY(0px);
    transition: transform 350ms ease-in-out;
  }
  span::after {
    transform: translateY(0px);
    transform: rotate(-270deg);
    transition: transform 350ms ease-in-out;
  }
}
.lightTheme nav {
  box-shadow: var(--bx-shadow);
}
nav {
  background: var(--clr-nav);
  position: absolute;
  text-align: center;
  width: 100%;
  top: 100%;
  left: 0;
  transform: scaleY(1);
  transform: translateY(-100%);
  z-index: -4;
  transform-origin: top;
  transition: transform 500ms ease-in-out;
  // box-shadow: var(--bx-shadow);
  border-bottom: var(--border);
  .nav-list {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
    left: 0;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 150ms ease-in;
    font-size: 1.5em;
    // background-color: red;
    color: var(--clr-text-hard);
    height: 100%;
    display: block;
    opacity: 0;
    padding: 0.4em 0.8em;

    &:hover {
      background-color: var(--clr-bg-soft);
    }
  }
}
.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}
.content-parent {
  background-color: var(--clr-bg);
  padding-top: 4em;
}
.content {
  margin: 0;
  padding: 0;
}

/* For Desktop */
@media screen and (min-width: 40em) {
  .nav-toggle-label {
    display: none;
  }

  .header {
    display: grid;
    /*                    space  links           space  */
    grid-template-columns: 1fr minmax(34em, 1fr) 1fr;
  }
  .lightTheme .header {
    box-shadow: var(--bx-shadow);
  }
  .nav {
    all: unset;
    grid-column: 2 / 3;
    display: flex;
    justify-content: space-around;
    box-shadow: unset !important;
    .nav-list {
      all: unset;
      display: flex;
      a {
        opacity: 1;
        padding: 0em 1.5em;
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }

  /*  CONTENT STUFF  */
  .content-parent {
    display: grid;
    margin-top: 4em;
    grid-template-columns: 1fr minmax(300px, 900px) 1fr;
    margin: 0;
  }
}
