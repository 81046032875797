.magnus-face {
    background-image: url(../ProjectsPage/img/magnus_crop_greyscale.png);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 20em auto;
    min-height: Max( calc( 100vh - 4em ) , 50em ) ;
}

.glasses-hover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20em;
    height: 20em;
    &::after {
        content: url(../../public/img/glasses.png);
        position: absolute;
        bottom: 10.5em;
        pointer-events: none;
        left: 6em;
        transform-origin: bottom left;
        transform: scale(0.18);
        transition: 1000ms;
    }
    &:not(:hover)::after {
        opacity: 0;
        transform: translateX(100vh) scale(0.18);
        transition-timing-function: ease-out;
    }
}

@function inverts($value) {
    @return #{ "invert(" + $value + ")" };
}
.link {
    display: flex;
    height: 3em;
    padding: 1em 0.0em;
    margin: 2em 0em;
    align-items: center;
    text-decoration: none;
    border-radius: 1em;
    transition: all;
    transition-duration: 350ms;
    color: var(--clr-text-hard);
    background: var(--clr-bg-hard);
    border: 1px solid var(--clr-bg-soft);
    &__img {
        height: 100%;
        margin: 1em;
        filter: inverts(var(--invert-svg));
    }
    &__name {
        font-size: 1em;
    }
}
.link:hover,
.link:focus {
    box-shadow: var(--bx-shadow);
}

/* For Desktop */
@media screen and (min-width: 40em) {
    .link {
        padding: 1em 2em;

        &__name {
            font-size: 1.5em;
        }
    }
}